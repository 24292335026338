import React from 'react';
import { Table } from "./components/Table";

export const App = () => {
    return (
        <Table>
            
        </Table>
    )
}
